import { jsxs as s, jsx as o } from "react/jsx-runtime";
import { forwardRef as t, memo as h } from "react";
const i = ({ title: r, titleId: e, ...l }, m) => /* @__PURE__ */ s(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    width: "1em",
    height: "1em",
    ref: m,
    "aria-labelledby": e,
    ...l,
    children: [
      r ? /* @__PURE__ */ o("title", { id: e, children: r }) : null,
      /* @__PURE__ */ o(
        "path",
        {
          fill: "currentColor",
          d: "M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2M12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8"
        }
      )
    ]
  }
), a = t(i), n = h(a);
export {
  n as default
};
