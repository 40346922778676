import { jsxs as m, jsx as r } from "react/jsx-runtime";
import { forwardRef as t, memo as d } from "react";
const i = ({ title: e, titleId: o, ...l }, s) => /* @__PURE__ */ m(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    width: "1em",
    height: "1em",
    ref: s,
    "aria-labelledby": o,
    ...l,
    children: [
      e ? /* @__PURE__ */ r("title", { id: o, children: e }) : null,
      /* @__PURE__ */ r(
        "path",
        {
          fill: "currentColor",
          fillRule: "evenodd",
          d: "m2 6.5 1.87 1.08a8.8 8.8 0 0 1 1.408-1.83 8.8 8.8 0 0 1 6.25-2.588 8.796 8.796 0 0 1 6.25 2.588 8.8 8.8 0 0 1 1.894 2.81 8.7 8.7 0 0 1 .696 3.44 8.796 8.796 0 0 1-2.588 6.25 8.8 8.8 0 0 1-6.25 2.588 8.784 8.784 0 0 1-4.312-1.12 8.9 8.9 0 0 1-3.346-3.3L2 17.5q.122.21.251.415A11 11 0 0 0 11.527 23c6.075 0 11-4.925 11-11s-4.925-11-11-11C7.457 1 3.901 3.211 2 6.5"
        }
      )
    ]
  }
), a = t(i), w = d(a);
export {
  w as default
};
