import clsx from "clsx";
import { memo } from "react";
import type { FC } from "react";
import { styled } from "goober";

import type { ImageFileNames } from "./image.types";

export type PictureProps = {
  fileName: ImageFileNames;
  alt: string;
  height: number | "inherit" | "auto";
  width: number | "inherit" | "100%";
  align?: "left" | "center" | "right";
  /**
   * Include a fallback file type if
   * the fallback type is anything other
   * than a png.
   */
  fallbacks?: ("jpg" | "jpeg")[];
  style?: React.CSSProperties;
};

const StyledPicture = styled("div")`
  &.a-center {
    margin: 0 auto;
  }
`;

export const Picture: FC<React.PropsWithChildren<PictureProps>> = memo(
  function Picture({
    fileName,
    alt,
    height,
    width,
    align = "center",
    fallbacks,
    style
  }) {
    return (
      <StyledPicture
        style={{ height, width, ...style }}
        className={clsx(`a-${align}`)}
      >
        <picture>
          <source srcSet={`/images/${fileName}.webp`} type="image/webp" />
          {fallbacks &&
            fallbacks.map((fallbackType) => (
              <source
                key={fileName}
                srcSet={`/images/${fileName}.${fallbackType}`}
                type={`image/${fallbackType}`}
              />
            ))}
          <img
            src={`/images/${fileName}.png`}
            alt={alt}
            height={height}
            width={width}
          />
        </picture>
      </StyledPicture>
    );
  }
);
