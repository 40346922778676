import type { LayoutProps } from "@machineq/components";
import { Layout, LayoutMain, LayoutNav } from "@machineq/components";
import dynamic from "next/dynamic";

import type { FeatureLayoutComponent } from "../page";

export type RootProps = Pick<LayoutProps, "mobileLayout"> & {
  hideNav?: boolean;
};

const Navbar = dynamic<Record<string, unknown>>(
  async () => import("@pwa/features/navbar").then((mod) => mod.Navbar),
  { ssr: false }
);

export const RootLayout: FeatureLayoutComponent<RootProps> = ({
  children,
  mobileLayout = "default",
  hideNav
}) => {
  return (
    <Layout mobileLayout={mobileLayout}>
      <LayoutMain>{children}</LayoutMain>
      {!hideNav && (
        <LayoutNav>
          <Navbar />
        </LayoutNav>
      )}
    </Layout>
  );
};

RootLayout.getLayout = (page, props) => (
  <RootLayout {...props}>{page}</RootLayout>
);
