import {
  ResponsiveDesktop,
  ResponsiveMobile,
  TypographyCopy,
  TypographyHeading
} from "@machineq/components";
import { makeRem } from "@machineq/theme";
import type { FC } from "react";
import type React from "react";
import { memo } from "react";
import { styled } from "goober";

import type { PictureProps } from "@pwa/components/image";
import { Picture } from "@pwa/components/image";

export type EmptyStatePageProps = {
  label: string;
  pictureProps?: Omit<PictureProps, "height" | "width">;
};

const StyledContainer = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: ${makeRem(32)};
`;

const StyledDiv = styled("div")`
  margin-bottom: ${makeRem(16)};
`;

const SDiv2 = styled("div")`
  & > * {
    margin: 0 auto;
  }
`;

export const EmptyStatePage: FC<React.PropsWithChildren<EmptyStatePageProps>> =
  memo(function EmptyStatePage({ label, children, pictureProps }) {
    return (
      <StyledContainer>
        <ResponsiveDesktop>
          <div
            style={{
              width: "100%"
            }}
          >
            {pictureProps?.fileName && (
              <StyledDiv>
                <Picture {...pictureProps} height={80} width={80} />
              </StyledDiv>
            )}

            <TypographyHeading mqVariant="h6" mqNode="div">
              {label}
            </TypographyHeading>
            <SDiv2
              style={{
                marginTop: makeRem(64)
              }}
            >
              {children}
            </SDiv2>
          </div>
        </ResponsiveDesktop>
        <ResponsiveMobile>
          <div
            style={{
              width: "100%"
            }}
          >
            <TypographyCopy mqVariant="body2" mqColor="textSecondary">
              {label}
            </TypographyCopy>
            <br />
            <SDiv2>{children}</SDiv2>
          </div>
        </ResponsiveMobile>
      </StyledContainer>
    );
  });
