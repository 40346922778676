// Included in the @sentry/nextjs package
 
import {
  getCurrentHub,
  rewriteFramesIntegration,
  init as SentryInit
} from "@sentry/node";

import { getRuntimeVariable } from "../env";

export const initializeSentry = () => {
  // don't do anything for sentry if this variable exists
  if (
    getRuntimeVariable("PWA_BROWSER_SENTRY_DISABLED", {
      verifyExistence: true
    }) === "true"
  ) {
    return;
  }

  // Sentry has been enabled... continue executing sentry commands
  if (getRuntimeVariable("PWA_BROWSER_SENTRY_DSN")) {
    const integrations = [];
    if (
      process.env.IS_SERVER === "true" &&
      getRuntimeVariable("PWA_BROWSER_SERVER_ROOT_DIR")
    ) {
      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      integrations.push(
        rewriteFramesIntegration({
          root: __dirname,
          iteratee: (frame) => {
            frame.filename = frame.filename?.replace(
              `~${getRuntimeVariable("PWA_BROWSER_SERVER_ROOT_DIR")}`,
              "app:///"
            );
            frame.filename = frame.filename?.replace(".next", "app");
            return frame;
          }
        })
      );
    }

    SentryInit({
      enabled: process.env.NODE_ENV === "production",
      integrations,
      dsn: getRuntimeVariable("PWA_BROWSER_SENTRY_DSN"),
      release: getRuntimeVariable("PWA_BROWSER_COMMIT_SHA")
    });

    /**
     * Work around to set the environment at run time... comes
     * from the Sentry support people
     */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getCurrentHub().getClient()._options.environment = getRuntimeVariable(
      "PWA_BROWSER_SENTRY_ENV"
    );
  }
};
