import type { ApiQueryParams } from "@machineq/models";

import { ClientFetcher } from "./api.ClientFetcher";
import { ClientInterceptor } from "./api.ClientInterceptor";
import type { ApiRequestConfig, ApiRequestError } from "./api.utils";
import { formatApiRequestUrlFromApiRequestConfig } from "./api.utils";

export class ApiClient extends ClientFetcher {
  private version: string;
  private domain: string;
  baseUrl: string;
  interceptors: {
    request: ClientInterceptor;
  };

  constructor({ version, domain }: { version: string; domain: string }) {
    super();
    this.version = version;
    this.domain = domain;
    this.domain = this.validateDomain(domain);
    this.interceptors = {
      request: new ClientInterceptor()
    };
    this.baseUrl = `${this.domain}/${this.version}`;
  }

  private validateDomain(domain: string) {
    // just in case :)
    return domain;
  }

  async fetch(
    url: RequestInfo,
    init?: RequestInit | undefined
  ): Promise<Response> {
    const requestUrl = `${this.baseUrl}${url}`;
    const requestConfig = await this.interceptors.request.apply({ ...init });

    return this.fetcher(requestUrl, requestConfig);
  }

  private parseReqBodyForError(body: BodyInit | null | undefined) {
    if (!body || typeof body !== "string") {
      return {};
    }
    try {
      const jsonBody = JSON.parse(body);
      return jsonBody;
    } catch {
      return {};
    }
  }

  async request<ApiRes = Record<string, unknown>>(
    url: RequestInfo,
    init?: RequestInit | undefined
  ): Promise<ApiRes> {
    const requestUrl = `${this.baseUrl}${url}`;
    const requestConfig = await this.interceptors.request.apply({
      ...init
    });

    return this.fetcher(requestUrl, requestConfig).then(async (res) => {
      // Handle Errors
      if (!res || !res.ok) {
        try {
          const data = await res.json();
          return Promise.reject<ApiRequestError>({
            ...data,
            config: {
              data: this.parseReqBodyForError(init?.body)
            }
          });
        } catch {
          const errText = await res.text();
          return Promise.reject({
            detail: errText,
            error_code: "unexpected_error",
            status_code: 500
          } as ApiRequestError);
        }
      }

      // Handle Success
      try {
        // Return true if a deletion occurred
        if (res.status === 204) {
          return Promise.resolve(true);
        }

        // Return true of a POST deletion occurred. This implementation
        // is kind of icky, but whatever. This is the best way to check
        // if the request is a POST deletion without cloning the response
        // and adding undue latency on the resolution of the promise for
        // every POST request.
        if (
          requestConfig.method === "POST" &&
          url.toString().includes("delete")
        ) {
          return Promise.resolve(true);
        }

        // Parse the JSON response and return the serialized data
        const data = await res.json();
        return Promise.resolve(data);
         
      } catch {
        return Promise.resolve(null);
      }
    });
  }

  get<ApiRes>(
    url: RequestInfo,
    init?: RequestInit | undefined
  ): Promise<ApiRes> {
    return this.request<ApiRes>(url, {
      ...init,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...init?.headers
      }
    });
  }

  getSpecial<ApiRes, ReqQPs = ApiQueryParams>(
    config: ApiRequestConfig<ReqQPs>,
    init?: RequestInit | undefined
  ): Promise<ApiRes> {
    const url = formatApiRequestUrlFromApiRequestConfig(config);
    return this.request<ApiRes>(url, {
      ...init,
      method: "GET",
      headers: {
        ...init?.headers,
        "Content-Type": "application/json"
      }
    });
  }

  post<ApiRes>(
    url: RequestInfo,
    init?: RequestInit | undefined
  ): Promise<ApiRes> {
    return this.request(url, {
      ...init,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...init?.headers
      }
    });
  }

  postSpecial<ApiRes, ApiRequest, ReqQPs = ApiQueryParams>({
    endpoint,
    body,
    init
  }: {
    endpoint: ApiRequestConfig<ReqQPs>;
    body: ApiRequest;
    init?: RequestInit | undefined;
  }): Promise<ApiRes> {
    const url = formatApiRequestUrlFromApiRequestConfig(endpoint);
    return this.request<ApiRes>(url, {
      ...init,
      method: "POST",
      body: body instanceof FormData ? body : JSON.stringify(body),
      headers: {
        ...init?.headers,
        "Content-Type": "application/json"
      }
    });
  }

  postMultipartFormData<ApiRes, ReqQPs = ApiQueryParams>({
    endpoint,
    body
  }: {
    endpoint: ApiRequestConfig<ReqQPs>;
    body: FormData;
  }): Promise<ApiRes> {
    const url = formatApiRequestUrlFromApiRequestConfig(endpoint);
    return this.request<ApiRes>(url, {
      method: "POST",
      body
    });
  }

  put<ApiRes>(
    url: RequestInfo,
    init?: RequestInit | undefined
  ): Promise<ApiRes> {
    return this.request(url, {
      ...init,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        ...init?.headers
      }
    });
  }

  putSpecial<ApiRes, ApiRequest, ReqQPs = ApiQueryParams>({
    endpoint,
    body,
    init
  }: {
    endpoint: ApiRequestConfig<ReqQPs>;
    body: ApiRequest;
    init?: RequestInit | undefined;
  }): Promise<ApiRes> {
    const url = formatApiRequestUrlFromApiRequestConfig(endpoint);

    return this.request<ApiRes>(url, {
      ...init,
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        ...init?.headers,
        "Content-Type": "application/json"
      }
    });
  }

  delete<ApiRes>(
    url: RequestInfo,
    init?: RequestInit | undefined
  ): Promise<ApiRes> {
    return this.request(url, {
      ...init,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        ...init?.headers
      }
    });
  }

  deleteSpecial<ApiRes, ReqQPs = ApiQueryParams>(
    config: ApiRequestConfig<ReqQPs>,
    init?: RequestInit | undefined
  ): Promise<ApiRes> {
    const url = formatApiRequestUrlFromApiRequestConfig(config);
    return this.request<ApiRes>(url, {
      ...init,
      method: "DELETE",
      headers: {
        ...init?.headers,
        "Content-Type": "application/json"
      }
    });
  }

  mutate<ApiRes, ApiRequest, ReqQPs = ApiQueryParams>({
    endpoint,
    method,
    body,
    init
  }: {
    endpoint: ApiRequestConfig<ReqQPs>;
    method: "PUT" | "POST";
    body?: ApiRequest;
    init?: RequestInit | undefined;
  }): Promise<ApiRes> {
    const url = formatApiRequestUrlFromApiRequestConfig(endpoint);

    return this.request<ApiRes>(url, {
      ...init,
      method,
      body: body ? JSON.stringify(body) : null,
      headers: {
        ...init?.headers,
        "Content-Type": "application/json"
      }
    });
  }
}
