import { jsxs as m, jsx as o } from "react/jsx-runtime";
import { forwardRef as a, memo as d } from "react";
const i = ({ title: e, titleId: l, ...r }, c) => /* @__PURE__ */ m(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    width: "1em",
    height: "1em",
    ref: c,
    "aria-labelledby": l,
    ...r,
    children: [
      e ? /* @__PURE__ */ o("title", { id: l, children: e }) : null,
      /* @__PURE__ */ o(
        "path",
        {
          fill: "currentColor",
          fillRule: "evenodd",
          d: "m13.23 4.808 6.564 12.657c.267.516.275 1.161.02 1.685-.254.524-.734.85-1.251.85H5.437c-.517 0-.997-.326-1.252-.85a1.91 1.91 0 0 1 .021-1.685L10.77 4.808C11.028 4.31 11.499 4 12 4c.5 0 .972.31 1.23.808m5.333 13.98a.39.39 0 0 0 .345-.233.53.53 0 0 0-.006-.464L12.339 5.434A.39.39 0 0 0 12 5.21a.39.39 0 0 0-.339.223L5.098 18.09a.53.53 0 0 0-.005.464.39.39 0 0 0 .344.234zm-6.558-9.804c-.396 0-.706.247-.706.686 0 .668.034 1.483.068 2.298s.068 1.63.068 2.298c0 .349.26.495.57.495.232 0 .56-.146.56-.495 0-.668.034-1.483.068-2.298s.068-1.63.068-2.298c0-.439-.32-.686-.696-.686m-.735 7.417c0-.471.32-.865.745-.865.396 0 .734.394.734.865 0 .461-.338.866-.735.866-.425 0-.744-.405-.744-.866",
          clipRule: "evenodd"
        }
      )
    ]
  }
), n = a(i), f = d(n);
export {
  f as default
};
