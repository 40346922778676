import type { ApiQueryParams } from "@machineq/models";
import useSWR, { useSWRConfig } from "swr";
import useSWRImmutable from "swr/immutable";

import type { ApiRequestConfig, ApiRequestError } from "./api.utils";
import { formatApiRequestUrlFromApiRequestConfig } from "./api.utils";

export type UseGetParams<QueryParams> = ApiRequestConfig<QueryParams> & {
  shouldLoad?: boolean;
};

/**
 * A function that takes a ApiRequestConfig and returns
 * another function that takes a promise to mutate the data
 * from the cache and then revalidate it.
 */
export function useMutateCache<R, QP = ApiQueryParams>(
  params: UseGetParams<QP> | undefined
) {
  const { mutate } = useSWRConfig();
  if (typeof params === "undefined") return;
  const key = formatApiRequestUrlFromApiRequestConfig(params);

  return function (fn: (fnParams: R) => Promise<R>) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mutate(key, fn, { revalidate: true });
  };
}

/**
 * An opinionated abstraction over the useSWR hook to make it
 * more inline with our apis and custom fetching mechanisms.
 * The return type is inferred from the implementation of SWR
 */
export function useGet<R, QP = ApiQueryParams>(
  params: UseGetParams<QP> | undefined
) {
  const shouldLoad = params?.shouldLoad ?? typeof params !== "undefined";

  const swr = useSWR<R, ApiRequestError>(
    shouldLoad && params
      ? formatApiRequestUrlFromApiRequestConfig<QP>(params)
      : null
  );
  return swr;
}

/**
 * An opinionated abstraction over the useSWRImmutable hook to make it
 * more inline with our apis and custom fetching mechanisms.
 * The return type is inferred from the implementation of SWR
 */
export function useGetImmutable<R, QP = ApiQueryParams>({
  shouldLoad = true,
  ...restEndpointParams
}: UseGetParams<QP>) {
  const swr = useSWRImmutable<R>(
    shouldLoad
      ? formatApiRequestUrlFromApiRequestConfig<QP>(restEndpointParams)
      : null
  );
  return swr;
}
