import { jsxs as l, jsx as e } from "react/jsx-runtime";
import { forwardRef as i, memo as n } from "react";
const m = ({ title: o, titleId: r, ...t }, d) => /* @__PURE__ */ l(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    width: "1em",
    height: "1em",
    ref: d,
    "aria-labelledby": r,
    ...t,
    children: [
      o ? /* @__PURE__ */ e("title", { id: r, children: o }) : null,
      /* @__PURE__ */ l("g", { fill: "none", fillRule: "evenodd", children: [
        /* @__PURE__ */ e("path", { fill: "currentColor", d: "m12 9 5 5H7z" }),
        /* @__PURE__ */ e("path", { d: "M0 0h24v24H0z" })
      ] })
    ]
  }
), a = i(m), f = n(a);
export {
  f as default
};
