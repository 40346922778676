import { jsxs as r, jsx as l } from "react/jsx-runtime";
import { forwardRef as o, memo as f } from "react";
const s = ({ title: e, titleId: i, ...n }, d) => /* @__PURE__ */ r(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink",
    viewBox: "0 0 24 24",
    width: "1em",
    height: "1em",
    ref: d,
    "aria-labelledby": i,
    ...n,
    children: [
      e ? /* @__PURE__ */ l("title", { id: i, children: e }) : null,
      /* @__PURE__ */ l("defs", { children: /* @__PURE__ */ l(
        "path",
        {
          id: "a",
          d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8m4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"
        }
      ) }),
      /* @__PURE__ */ r("g", { fill: "none", fillRule: "evenodd", children: [
        /* @__PURE__ */ l("mask", { id: "b", fill: "#fff", children: /* @__PURE__ */ l("use", { xlinkHref: "#a" }) }),
        /* @__PURE__ */ l("use", { xlinkHref: "#a", fill: "#FFF", fillOpacity: 0 }),
        /* @__PURE__ */ l("g", { fill: "currentColor", mask: "url(#b)", children: /* @__PURE__ */ l("path", { d: "M0 0h24.24v24.24H0z" }) }),
        /* @__PURE__ */ l("path", { d: "M0 0h24v24H0z" })
      ] })
    ]
  }
), a = o(s), t = f(a);
export {
  t as default
};
